import { ID } from "../../../src/shared/constants/constants";
import { Input, InputProps } from "./input";
import { Label } from "./label";
import { cn } from "@/lib/utils";

export function InputWithLabel({
  label,
  name,
  error = "",
  ...props
}: {
  label: string;
  name: string;
  error?: string;
} & InputProps) {
  return (
    <>
      <Label id={`${ID.LABEL}_${name}`} htmlFor={name} className={cn(ID.LABEL, !!error ? "text-red-500" : "")}>
        {label}
      </Label>
      <Input
        id={name}
        onWheel={(e) => {
          e.currentTarget.blur();
        }}
        {...props}
      />
      {!!error?.length && (
        <p className="text-xs font-medium text-destructive">{error}</p>
      )}
    </>
  );
}
