import { ArrowDownUp } from "lucide-react";
import { InputWithLabel } from "../../@/components/ui/input-with-label";
import { AmountInput } from "../../@/components/ui/amount-input";
import { Label } from "../../@/components/ui/label";
import { Loader } from "../../@/components/ui/loader";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../@/components/ui/select";
import { DirectionType } from "../../src/shared/types/types";
import { ID } from "../../src/shared/constants/constants";
import { useCalculator } from "./use-calculator";
import "./calculator.css";

export const Calculator = () => {
  const {
    citiesOptions,
    fromOptions,
    toOptions,
    createBidError,
    error,
    fromAmountPlaceholder,
    isSwapAvailable,
    handleAmountChange,
    handleTelegramChange,
    handleFromChange,
    handleToChange,
    handleCityChange,
    swap,
    submit,
    isPending,
    from,
    amount,
    to,
    city,
  } = useCalculator();

  return (
    <section
      id="exchange">
      <div id={ID.CARD} className={ID.CARD}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submit();
          }}>
          <h2 id={ID.TITLE} className={ID.TITLE}>
            Введите данные для обмена
          </h2>
          <div className="flex flex-col gap-4 mb-8">
            <div className="flex flex-col gap-2">
              <AmountInput
                currencyOptions={fromOptions}
                selectedCurrency={from}
                onCurrencyChange={handleFromChange}
                value={amount.from}
                directionType={DirectionType.FROM}
                onChange={(e) => handleAmountChange(e, DirectionType.FROM)}
                label={error.from || "Отдаете"}
                hasError={!!error.from}
                placeholder={fromAmountPlaceholder}
              />
              <button
                type="button"
                id={ID.SWOP_BUTTON}
                className={ID.SWOP_BUTTON}
                onClick={swap}
                disabled={!isSwapAvailable}>
                <ArrowDownUp />
              </button>
              <AmountInput
                currencyOptions={toOptions}
                selectedCurrency={to}
                onCurrencyChange={handleToChange}
                value={amount.to}
                directionType={DirectionType.TO}
                onChange={(e) => handleAmountChange(e, DirectionType.TO)}
                label="Получаете"
              />
              <Label id={`${ID.LABEL}_city`} className={ID.LABEL}>
                Город
              </Label>
              <Select
                value={city?.label}
                onValueChange={(e) => {
                  const currentOption = citiesOptions?.find((el) => el.label === e);
                  if (!!currentOption) {
                    handleCityChange(currentOption);
                  }
                }}>
                <SelectTrigger>
                  <SelectValue placeholder="Select..." />
                </SelectTrigger>
                <SelectContent>
                  {citiesOptions?.map((el) => (
                    <SelectItem key={el.value} value={el.label}>
                      {el.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <InputWithLabel
                label={"Telegram"}
                name="telegram"
                placeholder="@telegram"
                onChange={handleTelegramChange}
                error={error.telegram}
              />
            </div>
          </div>
          <button
            id={ID.SUBMIT_BUTTON}
            type="submit"
            className={ID.SUBMIT_BUTTON}
          >
            {isPending ? <Loader /> : "Создать заявку"}
          </button>
          {createBidError && (
            <p id={ID.ERROR} className={ID.ERROR}>
              При создании заявки произошла ошибка
            </p>
          )}
        </form>
      </div>
    </section>
  );
};
