export const formatNumber = (val: string) => {
  let res = "";

  for (let char of val) {
    if (char === ",") {
      char = ".";
    }

    res += char;
  }

  return res;
};

export const toFixed = (x: string) => {
  if (Math.abs(+x) < 1.0) {
    const e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x = String(+x * Math.pow(10, e - 1));
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    let e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x = String(+x / Math.pow(10, e));
      x += new Array(e + 1).join("0");
    }
  }
  return x;
};

export const handleNumberInput = (val: string) => {
  let res = "";
  let separatorIncluded = false;

  for (const char of val) {
    const isSeparator = char === "." || char === ",";

    if (
      !/^[0-9,.]+$/.test(char) ||
      (separatorIncluded && isSeparator) ||
      (res.length === 0 && isSeparator) ||
      (res === "0" && !isSeparator)
    ) {
      continue;
    }

    separatorIncluded = separatorIncluded || isSeparator;
    res += char;
  }

  return res;
};

export const handleTelegramInput = (value: string) => {
  value = value.replaceAll("@", "");
  return "@" + value;
};
