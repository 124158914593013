
import { useCalculatorActions } from "./use-calculator-actions";
import { useCalculatorData } from "./use-calculator-data";
import { useCalculatorEffects } from "./use-calculator-effects";

export const useCalculator = () => {
  const {
    lastUsedInput,
    amount,
    from,
    to,
    city,
    telegram,
    citiesOptions,
    directions,
    fromOptions,
    toOptions,
    createBidError,
    error,
    course,
    setTelegram,
    setFrom,
    setTo,
    setAmount,
    setLastUsedInput,
    setError,
    setCreateBidError,
    fromAmountPlaceholder,
    isSwapAvailable,
    setCity,
    inited,
    setInited,
  } = useCalculatorData();

  const {
    handleAmountChange,
    handleTelegramChange,
    handleFromChange,
    handleToChange,
    handleCityChange,
    swap,
    submit,
    isPending,
    isError,
  } = useCalculatorActions({
    course,
    amount,
    setAmount,
    setLastUsedInput,
    setError,
    telegram,
    setTelegram,
    directions,
    setCity,
    city,
    from,
    to,
    setFrom,
    setTo,
  });

  useCalculatorEffects({
    lastUsedInput,
    amount,
    from,
    to,
    course,
    isError,
    setAmount,
    setTo,
    directions,
    setFrom,
    setCity,
    setCreateBidError,
    citiesOptions,
    handleCityChange,
    inited,
    setInited,
  });

  return {
    citiesOptions,
    fromOptions,
    toOptions,
    createBidError,
    error,
    fromAmountPlaceholder,
    isSwapAvailable,
    setCity,
    handleAmountChange,
    handleTelegramChange,
    handleFromChange,
    handleToChange,
    handleCityChange,
    swap,
    submit,
    isPending,
    from,
    amount,
    to,
    city,
    telegram,
  };
};
