import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "../@/components/ui/toaster";
import { LangEnum, useCommonStore } from "./shared/store/common-store";
import { Calculator } from "./calculator/calculator";
import "./index.css";

function App() {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 1,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
          },
        },
      })
  );

  const { setLanguage } = useCommonStore();

  const html_lang = document.getElementsByTagName("html")?.[0]?.lang;

  useEffect(() => {
    if (!!html_lang && Object.values(LangEnum)?.includes(html_lang as LangEnum)) {
      setLanguage(html_lang as LangEnum);
    }
  }, [html_lang]);

  return (
    <QueryClientProvider client={queryClient}>
      <Calculator />
      <Toaster />
    </QueryClientProvider>
  );
}

export default App;
