export const BASE_URL = import.meta.env.VITE_API_ENDPOINT;
export const CHAT_ID = import.meta.env.VITE_TG_CHAT_ID;
const BOT_TOKEN = import.meta.env.VITE_TG_BOT_TOKEN;
export const TG_URL = `https://api.telegram.org/bot${BOT_TOKEN}`;

// eslint-disable-next-line
export const MAIL_REGEX = /^[A-Za-z0-9\._+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,7}$/;
export const TELEGRAM_REGEX = /^[a-zA-Z0-9_@]{0,32}$/;

export enum ID {
  ROOT = "submodule-calculator-root",
  CARD = "submodule-calculator-card",
  TITLE = "submodule-calculator-title",
  LABEL = "submodule-calculator-label",
  INPUT = "submodule-calculator-input",
  SELECT = "submodule-calculator-select",
  ERROR = "submodule-calculator-error",
  SUBMIT_BUTTON = "submodule-calculator-button-submit",
  SWOP_BUTTON = "submodule-calculator-button-swop",
}
