import { useBaseQuery } from "../base-query";
import exchangeService from "../../services/exchange-service";
import { useBaseMutation } from "../base-mutation";
import {
  City,
  Direction,
  OrderInfo,
  OrderPayload,
  OrderResponse,
  RatePayload,
  RateResponse,
  TG_OrderPayload,
} from "../../../types/types";

export const useCities = () =>
  useBaseQuery<null, City[]>(["cities"], () => exchangeService.cities());

export const useDirections = (city_code: string | undefined) =>
  useBaseQuery<null, Direction[]>(
    ["directions", city_code || ""],
    () => exchangeService.directions(city_code || ""),
    !!city_code
  );

export const useCourse = (payload: RatePayload | null) =>
  useBaseMutation<RatePayload, RateResponse>(
    ["rate", payload?.city_code || "", payload?.give_code || "", payload?.get_code || ""],
    (payload: RatePayload) => exchangeService.rate(payload),
    !!payload
  );

export const useCreateOrder = () =>
  useBaseMutation<OrderPayload, OrderResponse>(["create-order"], (payload: OrderPayload) =>
    exchangeService.createOrder(payload)
  );

export const useCreateOrderTG = () =>
  useBaseMutation<TG_OrderPayload, OrderResponse>(["create-order-tg"], (payload: TG_OrderPayload) =>
    exchangeService.createOrderTG(payload)
  );

export const useOrder = (uuid: string | undefined) =>
  useBaseQuery<null, OrderInfo>(
    ["order", uuid || ""],
    () => exchangeService.order(uuid || ""),
    !!uuid
  );
