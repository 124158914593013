import { CurrencyOption, ExchangeVariant, _Option } from "../types/types";

export const CurrencyLabel = ({ label, value }: { label: string; value: string }) => {
  return (
    <div className="flex items-center text-left selection:text-[#ffffff00]">
      {!!value && (
        <img
          src={`src/shared/image/currencies/${value}.svg`}
          className="min-w-[40px] w-[40px] sm:min-w-[45px] mr-[20px] rounded-2"
          alt={value}
          width={40}
          height={40}
        />
      )}
      {label}
    </div>
  );
};

export const convertCurrenciesToOptions = (arr: _Option[]): CurrencyOption[] => {
  return (
    arr?.map((item) => {
      return {
        label: (
          <CurrencyLabel
            label={item.label}
            value={item.value}
          />
        ),
        value: item?.value,
        round: item?.round || 0,
        category: item?.category || ExchangeVariant.CASH,
      };
    }) || ([] as CurrencyOption[])
  );
};
