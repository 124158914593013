import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export function useBaseMutation<P, R>(
  mutationKeys: string[],
  request: (payload: P) => Promise<AxiosResponse<R, string>>,
  enabled: boolean = true
) {
  const { mutateAsync, isPending, error } = useMutation({
    mutationKey: mutationKeys,
    mutationFn: async (payload: P) => {
      if (enabled) {
        try {
          if (mutationKeys?.some((el) => el === "")) {
            return null;
          }
          const data = await request(payload);
          if (!data) {
            return null;
          }
          if (data.status < 200 || data.status >= 300) {
            throw new Error(data.status?.toString());
          }
          return data.data;
        } catch (e) {
          throw new Error(e as string);
        }
      }
    },
  });

  return {
    mutateAsync,
    isPending,
    error,
  };
}
