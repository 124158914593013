import { ReactNode } from "react";

export type _Select = {
  label: string;
  value: string;
};

export interface _Option {
  value: string;
  label: string;
  round?: number;
  category?: ExchangeVariant;
}

export interface CurrencyOption {
  value: string;
  label: ReactNode;
  round: number;
  category: ExchangeVariant;
}

export type ErrorType = {
  from: string;
  telegram: string;
};

export enum ExchangeVariant {
  CASH = "cash",
  CRYPTO = "crypto",
  CARD = "card",
}

export interface ItemNameByLang {
  ru: string;
  en: string;
}

export type City = {
  city_code: string;
  city_name: ItemNameByLang;
};

export enum DirectionType {
  FROM = "from",
  TO = "to",
}

export type Amount = {
  from: string;
  to: string;
};

export interface Direction {
  give: Currency;
  get: Currency[];
}

export interface Currency {
  currency_code: string;
  currency_name: ItemNameByLang;
  currency_type: ExchangeVariant;
  round: number;
}

export interface RatePayload {
  city_code: string;
  give_code: string;
  get_code: string;
}

export interface RateResponse {
  rate_get: number;
  rate_give: number;
  min: number;
  max: number;
  terms: string;
  rate: number;
}

export enum Status {
  CREATED = "created",
  RECEIVED = "received",
  DONE = "done",
  ERROR = "error",
  PENDING = "pending",
  EXPIRED = "expired",
}

export enum RequisitesVariants {
  CARD = "card",
  TRX = "trx",
  ETH = "eth",
  BTC = "btc",
}

export interface OrderInfo {
  id: number;
  created: string; // 2024-05-08 15:37:15.246995
  status: Status;
  info: {
    link: string; // ссылка на зявку
    terms: string;
  };
  trade: OrderContent;
  transaction: {
    link: string | null; // ссылка на транзакцию
    requisites_give: Requisites | null;
    requisites_get: Requisites | null;
  } | null; // null === 206
}

export interface OrderContent {
  give: string;
  get: string;
  type_give: ExchangeVariant | null;
  type_get: ExchangeVariant | null;
  amount_give: string; // с учетом комиссии
  amount_get: string;
  fees?: string;
  rate_give: number;
  rate_get: number;
  cash_point?: ItemNameByLang;
  city_code?: string;
}

export interface Requisites {
  requisites: string;
  type: RequisitesVariants;
}

export interface OrderPayload {
  telegram: string;
  utm?: string;
  utm_medium?: string;
  roistat_visit?: string | null;
  ref_id: string | null;
  trade: OrderContent;
  requisites?: string;
}

export interface TG_OrderPayload {
  chat_id: string;
  text: string;
  parse_mode: "HTML";
  message_thread_id: string;
  disable_web_page_preview: true;
}

export interface OrderResponse {
  trade_id: string;
}
