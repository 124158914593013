import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useCities, useCourse, useDirections } from "../../../src/shared/api/query/use-exchange/use-exchange";
import { LangEnum, useCommonStore } from "../../../src/shared/store/common-store";
import { Amount, CurrencyOption, DirectionType, ErrorType, RatePayload, RateResponse, _Select } from "../../../src/shared/types/types";
import { convertCurrenciesToOptions } from "../../../src/shared/utils/convert-to-options";

export const useCalculatorData = () => {
  const queryClient = useQueryClient();
  const [inited, setInited] = useState(false);
  const [lastUsedInput, setLastUsedInput] = useState<DirectionType>(DirectionType.FROM);
  const [amount, setAmount] = useState<Amount>({
    from: "1",
    to: "1",
  });
  const [from, setFrom] = useState<CurrencyOption | null>(null);
  const [to, setTo] = useState<CurrencyOption | null>(null);
  const [city, setCity] = useState<_Select | null>(null);
  const [telegram, setTelegram] = useState("");

  const [course, setCourse] = useState<RateResponse | null>(null);

  const { data: cities } = useCities();
  const { data: directions } = useDirections(city?.value);
  const [createBidError, setCreateBidError] = useState(false);
  const [error, setError] = useState<ErrorType>({
    from: "",
    telegram: "",
  });

  const { language } = useCommonStore();
  const lang_attr = language?.toLowerCase() === LangEnum.RUSSIAN?.toLowerCase() ? "ru" : "en";

  const citiesOptions: _Select[] = useMemo(() => cities?.map((el) => ({
    label: el.city_name[lang_attr],
    value: el.city_code,
  })) || [], [cities]);

  const fromOptions: CurrencyOption[] = useMemo(() => {
    if (!!directions) {
      return convertCurrenciesToOptions(
        directions?.map((el) => ({
          label: el.give.currency_name[lang_attr],
          value: el.give.currency_code,
          round: el.give.round,
          category: el.give.currency_type,
        }))
      );
    } else {
      return [];
    }
  }, [directions]);

  const toOptions: CurrencyOption[] = useMemo(() => {
    if (!!directions && !!from) {
      return convertCurrenciesToOptions(
        directions
          ?.find((el) => el.give.currency_code === from.value)
          ?.get?.map((el) => ({
            label: el.currency_name[lang_attr],
            value: el.currency_code,
            round: el.round,
            category: el.currency_type,
          })) || []
      );
    } else if (!!directions) {
      return convertCurrenciesToOptions(
        directions[0]?.get?.map((el) => ({
          label: el.currency_name[lang_attr],
          value: el.currency_code,
          round: el.round,
          category: el.currency_type,
        }))
      );
    } else {
      return [];
    }
  }, [directions, from]);

  const { mutateAsync: getCourse, error: courseError } = useCourse(
    !!from && !!to && !!city && from?.value !== to?.value
      ? {
        city_code: city?.value,
        give_code: from?.value,
        get_code: to?.value,
      }
      : null
  );

  const gettingCourses = async () => {
    if (!!from && !!to && !!city && from?.value !== to?.value) {
      let payload: RatePayload | null = null;
      const regex = /CASH/;
      if (regex.test(to.value) !== regex.test(from.value)) {
        payload = {
          city_code: city.value,
          give_code: from.value,
          get_code: to.value,
        };
      }

      if (!!payload) {
        const response = await getCourse(payload);
        if (response) {
          setCourse(response);
        } else {
          queryClient.invalidateQueries({ queryKey: ["rate"] });
          setCourse(null);
        }
      } else {
        setCourse(null);
      }
    } else {
      setCourse(null);
    }
  };

  const isSwapAvailable = useMemo(() => {
    if (!directions) {
      return false;
    }
    const fromSwop = directions?.find((el) => el.give.currency_code === to?.value);
    const toSwop = fromSwop?.get?.find((el) => el.currency_code === from?.value);
    return !!fromSwop && !!toSwop;
  }, [from, to, directions]);


  const fromAmountPlaceholder = course ? `${course.min} - ${course.max}` : undefined;

  useEffect(() => {
    if (!!courseError) {
      setCourse(null);
    }
  }, [courseError]);

  useEffect(() => {
    gettingCourses();
    const courseInterval = setInterval(() => {
      gettingCourses();
    }, 30000);
    return () => {
      clearInterval(courseInterval);
    };
  }, [from, to, city]);

  return {
    lastUsedInput,
    amount,
    from,
    to,
    city,
    telegram,
    citiesOptions,
    directions,
    fromOptions,
    toOptions,
    createBidError,
    error,
    course,
    isSwapAvailable,
    fromAmountPlaceholder,
    setTelegram,
    setFrom,
    setTo,
    setAmount,
    setLastUsedInput,
    setError,
    setCreateBidError,
    setCity,
    inited,
    setInited,
  };
};

