import { create } from "zustand";
import { persist } from "zustand/middleware";

export enum LangEnum {
  ENGLISH = "EN",
  SPANISH = "ES",
  FRENCH = "FR",
  GERMAN = "DE",
  ITALIAN = "IT",
  JAPANESE = "JA",
  KOREAN = "KO",
  CHINESE_SIMPLIFIED = "ZH-CN",
  CHINESE_TRADITIONAL = "ZH-TW",
  PORTUGUESE = "PT",
  RUSSIAN = "RU",
  ARABIC = "AR",
  HINDI = "HI",
  BENGALI = "BN",
  PUNJABI = "PA",
  JAVANESE = "JV",
  TURKISH = "TR",
  VIETNAMESE = "VI",
  PERSIAN = "FA",
  POLISH = "PL",
  DUTCH = "NL",
  GREEK = "EL",
  HEBREW = "HE",
  SWEDISH = "SV",
  NORWEGIAN = "NO",
  FINNISH = "FI",
  DANISH = "DA",
  GEORGIAN = "KA",
}

interface CommonStoreState {
  language: LangEnum;
  languages: LangEnum[];
  setLanguage: (language: LangEnum) => void;
}

const initialState = {
  language: LangEnum.RUSSIAN,
  languages: Object.values(LangEnum),
};

export const useCommonStore = create<CommonStoreState>()(
  persist(
    (set) => ({
      ...initialState,
      setLanguage: (language) => set({ language }),
    }),
    {
      name: "commonStore",
    }
  )
);
