// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck - Roistat Metrika

export const roistatTelegramGoal = () => {
  window?.roistat?.event?.send("tg");
};

export const roistatChat = () => {
  window?.roistat?.event?.send("chat");
};

export const roistatOrder = () => {
  window?.roistat?.event?.send("order");
};
