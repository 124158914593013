import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ID } from "./src/shared/constants/constants";
import App from "./src/App";
import "./src/index.css";

const container = document.getElementById(ID.ROOT);
const root = !!container && createRoot(container);

root &&
  root.render(
    <>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </>
  );
