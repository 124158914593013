import { Dispatch, SetStateAction, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { LangEnum, useCommonStore } from "../../../src/shared/store/common-store";
import { Amount, CurrencyOption, Direction, DirectionType, RateResponse, _Select } from "../../../src/shared/types/types";
import { formatNumber, toFixed } from "../../../src/shared/utils/format-inputs";


export const useCalculatorEffects = ({
  course,
  lastUsedInput,
  amount,
  setAmount,
  directions,
  citiesOptions,
  handleCityChange,
  from,
  to,
  setFrom,
  setTo,
  setCity,
  isError,
  setCreateBidError,
  inited,
  setInited,
}: {
  course: RateResponse | null;
  lastUsedInput: DirectionType;
  amount: Amount;
  setAmount: Dispatch<SetStateAction<Amount>>;
  directions: Direction[] | undefined | null;
  citiesOptions: _Select[];
  handleCityChange: (option: _Select) => void;
  from: CurrencyOption | null;
  to: CurrencyOption | null;
  setFrom: (option: CurrencyOption) => void;
  setTo: (option: CurrencyOption) => void;
  setCity: (option: _Select) => void;
  isError: boolean;
  setCreateBidError: (val: boolean) => void;
  inited: boolean;
  setInited: (val: boolean) => void;
}) => {
  const [searchParams] = useSearchParams();
  const { language } = useCommonStore();
  const lang_attr = language?.toLowerCase() === LangEnum.RUSSIAN?.toLowerCase() ? "ru" : "en";

  // course change
  useEffect(() => {
    if (!course) {
      return;
    }

    const pair_course = course.rate_get / course.rate_give;
    let direction: DirectionType;
    let value: string;

    switch (lastUsedInput) {
      case "from":
        direction = DirectionType.TO;
        value = toFixed(String(+formatNumber(amount.from) * pair_course));
        break;
      case "to":
        direction = DirectionType.FROM;
        value = toFixed(String(+formatNumber(amount.to) / pair_course));
        break;
    }

    setAmount((prev) => ({
      ...prev,
      [direction]: value,
    }));
  }, [course, lastUsedInput]);

  useEffect(() => {
    const search_city = searchParams?.get("city");
    if (
      !!citiesOptions?.length &&
      (!search_city ||
        !citiesOptions?.find((el) => el.value === search_city?.toLowerCase()))
    ) {
      setCity(citiesOptions[0]);
    }
  }, [citiesOptions])

  useEffect(() => {
    if (!directions) {
      return;
    }

    const newDirection = directions.find((item) => item.give.currency_code === from?.value);

    const newFrom: CurrencyOption = (!!newDirection && !!from)
      ? from
      : {
        label: directions[0].give.currency_name[lang_attr],
        value: directions[0].give.currency_code,
        round: directions[0].give.round,
        category: directions[0].give.currency_type,
      };

    const newAvailableTo = !!newDirection ? newDirection.get : directions[0].get;

    const newTo: CurrencyOption = (!!to && newAvailableTo.some((item) => item.currency_code === to?.value))
      ? to
      : {
        label: newAvailableTo[0].currency_name[lang_attr],
        value: newAvailableTo[0].currency_code,
        round: newAvailableTo[0].round,
        category: newAvailableTo[0].currency_type,
      };

    setFrom(newFrom);
    setTo(newTo);
  }, [directions]);

  useEffect(() => {
    if (isError) {
      setCreateBidError(true);

      setTimeout(() => {
        setCreateBidError(false);
      }, 3000);
    }
  }, [isError]);

  useEffect(() => {
    if (inited || !directions || citiesOptions.length === 0) {
      return;
    }

    const cur_from = searchParams.get("cur_from")?.replace("20", "");
    const cur_to = searchParams.get("cur_to")?.replace("20", "");
    const city_params = searchParams.get("city");

    if (city_params) {
      const city = citiesOptions.find(
        (item) => item.value.toLowerCase() === city_params.toLowerCase()
      );
      handleCityChange(city || { label: "Москва", value: "msk" });
    }

    const selectedFrom = directions.find(
      (item) => item.give.currency_code.toLowerCase() === (cur_from || "USDTTRC").toLowerCase()
    );

    const from: CurrencyOption = selectedFrom
      ? {
        label: selectedFrom.give.currency_name[lang_attr],
        value: selectedFrom.give.currency_code,
        round: selectedFrom.give.round,
        category: selectedFrom.give.currency_type,
      }
      : {
        label: directions[0].give.currency_name[lang_attr],
        value: directions[0].give.currency_code,
        round: directions[0].give.round,
        category: directions[0].give.currency_type,
      };

    const availableTo = !!selectedFrom ? selectedFrom.get : directions[0].get;
    const selectedTo = availableTo.find((item) => item.currency_code === (cur_to || "CASHRUB"));

    const to: CurrencyOption = selectedTo
      ? {
        label: selectedTo.currency_name[lang_attr],
        value: selectedTo.currency_code,
        round: selectedTo.round,
        category: selectedTo.currency_type,
      }
      : {
        label: availableTo[0].currency_name[lang_attr],
        value: availableTo[0].currency_code,
        round: availableTo[0].round,
        category: availableTo[0].currency_type,
      };

    setFrom(from);
    setTo(to);

    setTimeout(() => {
      setInited(true);
    }, 300);
  }, [directions, citiesOptions, inited]);
};

