import { cn } from "../../../@/lib/utils";
import { CurrencyOption, DirectionType } from "../../../src/shared/types/types";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./select";
import { Input } from "./input";
import { ID } from "../../../src/shared/constants/constants";

interface AmountInputProps {
  label: string;
  placeholder?: string;
  value: string;
  directionType: DirectionType;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  currencyOptions: CurrencyOption[];
  selectedCurrency: CurrencyOption | null;
  onCurrencyChange: (option: CurrencyOption) => void;
  hasError?: boolean;
}

export const AmountInput = ({
  label,
  placeholder,
  value,
  directionType,
  onChange,
  currencyOptions,
  selectedCurrency,
  onCurrencyChange,
  hasError,
}: AmountInputProps) => (
  <div className="flex flex-col w-full">
    <p id={`${ID.LABEL}_${directionType}`} className={cn(ID.LABEL, hasError && "text-red-500")}>
      {label}
    </p>
    <div className="bg-secondary rounded-[10px]">
      <div className="flex items-center">
        <Input
          name={directionType}
          value={value}
          onChange={onChange}
          placeholder={placeholder || "Количество"}
          className="rounded-r-none border-r-0"
        />
        <Select
          name={directionType}
          value={selectedCurrency?.value}
          onValueChange={(e) => {
            const currentOption = currencyOptions?.find((el) => el.value === e);
            if (!!currentOption) {
              onCurrencyChange(currentOption);
            }
          }}>
          <SelectTrigger className="px-0 lg: pr-3 w-[42px] min-w-[42px] rounded-l-none lg:w-[260px] overflow-hidden">
            <SelectValue placeholder="Select..." />
          </SelectTrigger>
          <SelectContent>
            {currencyOptions?.map((el) => (
              <SelectItem key={el.value} value={el.value}>
                {el.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  </div>
);
