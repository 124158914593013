import axios from "axios";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { useCommonStore } from "../store/common-store";

const api = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

const getFp = async () => {
  const fp = await FingerprintJS.load();
  const result = await fp.get();
  return result.visitorId;
};

axios.defaults.withCredentials = true;

api.interceptors.request.use(async (config) => {
  if (!config?.headers) {
    throw new Error(`Expected 'config' and 'config.headers' not to be undefined`);
  }
  const language = useCommonStore.getState().language;
  const fingerprint = await getFp();
  config.headers.set("User-Identifier", fingerprint);
  config.headers.set("User-Language", language?.toLowerCase() || "ru");

  return config;
});

api.interceptors.response.use(
  (config) => {
    return config;
  },
  async (err) => {
    if (err.message === "Network Error") {
      throw new Error("500");
    }
    return err?.response;
  }
);

export default api;
