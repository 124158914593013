import { BASE_URL, TG_URL } from "../../constants/constants";
import {
  City,
  Direction,
  OrderInfo,
  OrderPayload,
  OrderResponse,
  RatePayload,
  RateResponse,
  TG_OrderPayload,
} from "../../types/types";
import api from "../api-instance";

class ExchangesService {
  async cities() {
    return api.get<City[]>(`${BASE_URL}/cities/`);
  }
  async directions(city_code: string) {
    return api.get<Direction[]>(`${BASE_URL}/directions/?city_code=${city_code}`);
  }
  async rate(payload: RatePayload) {
    return api.post<RateResponse>(`${BASE_URL}/directions/course`, payload);
  }
  async createOrder(payload: OrderPayload) {
    return api.post<OrderResponse>(`${BASE_URL}/order/create`, payload);
  }
  async createOrderTG(payload: TG_OrderPayload) {
    return api.post<OrderResponse>(`${TG_URL}/sendMessage`, payload);
  }
  async order(uuid: string) {
    return api.get<OrderInfo>(`${BASE_URL}/order/get?bid_link=${uuid}`, {
      headers: {
        uuid,
      },
    });
  }
}

export default new ExchangesService();
