import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import { v4 } from "uuid";
import { useCreateOrder, useCreateOrderTG, useOrder } from "../../../src/shared/api/query/use-exchange/use-exchange";
import { metrika } from "../../../src/shared/utils/metrika";
import { formatNumber, handleNumberInput, handleTelegramInput, toFixed } from "../../../src/shared/utils/format-inputs";
import { Amount, CurrencyOption, Direction, DirectionType, ErrorType, OrderPayload, RateResponse, TG_OrderPayload, _Select } from "../../../src/shared/types/types";
import { CHAT_ID, TELEGRAM_REGEX } from "../../../src/shared/constants/constants";
import { LangEnum, useCommonStore } from "../../../src/shared/store/common-store";

interface UseCalculatorActionsProps {
  course: RateResponse | null | undefined;
  amount: Amount;
  setAmount: Dispatch<SetStateAction<Amount>>;
  setLastUsedInput: (val: DirectionType) => void;
  setError: Dispatch<SetStateAction<ErrorType>>;
  telegram: string;
  setTelegram: (val: string) => void;
  directions: Direction[] | undefined | null;
  city: _Select | null;
  from: CurrencyOption | null;
  to: CurrencyOption | null;
  setFrom: (from: CurrencyOption) => void;
  setTo: (to: CurrencyOption) => void;
  setCity: (city: _Select) => void;
}

const rid_attr = "ref_id";

export const useCalculatorActions = ({
  course,
  amount,
  setAmount,
  setLastUsedInput,
  setError,
  telegram,
  setTelegram,
  directions,
  city,
  from,
  to,
  setFrom,
  setTo,
  setCity,
}: UseCalculatorActionsProps) => {
  const [searchParams] = useSearchParams();
  const [orderId, setOrderId] = useState<string | undefined>();
  const { mutateAsync: createBid, isPending, error } = useCreateOrder();
  const { mutateAsync: createBidTg, isPending: isPendingTg, error: errorTg } = useCreateOrderTG();
  const { data: order, isPending: isOrderPending, error: orderError } = useOrder(orderId);

  const { language } = useCommonStore();
  const lang_attr = language?.toLowerCase() === LangEnum.RUSSIAN?.toLowerCase() ? "ru" : "en";

  const handleAmountChange = (e: ChangeEvent<HTMLInputElement>, direction: DirectionType) => {
    if (!course) {
      return;
    }

    const value = formatNumber(handleNumberInput(e.target.value));
    const pair_course = course.rate_get / course.rate_give;
    let opposite: DirectionType;
    let oppositeValue: string;

    switch (direction) {
      case "from":
        opposite = DirectionType.TO;
        oppositeValue = toFixed(String(+formatNumber(value) * pair_course));
        break;
      case "to":
        opposite = DirectionType.FROM;
        oppositeValue = toFixed(String(+formatNumber(value) / pair_course));
        break;
    }

    setAmount((prev) => ({
      ...prev,
      [opposite]: oppositeValue,
      [direction]: value,
    }));

    setLastUsedInput(direction);
    clearError("from");
  };

  const clearError = (field: keyof ErrorType) => {
    setError((prev) => ({
      ...prev,
      [field]: "",
    }));
  };

  const handleTelegramChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTelegram(handleTelegramInput(e.target.value));
    clearError("telegram");
  };

  const handleFromChange = (option: CurrencyOption) => {
    const newAvailableTo = directions?.find((el) => el.give.currency_code === option.value)?.get || [];
    const newTo: CurrencyOption = (!!to && newAvailableTo.some((item) => item.currency_code === to.value))
      ? to
      : {
        value: newAvailableTo[0].currency_code,
        label: newAvailableTo[0].currency_name[lang_attr],
        round: newAvailableTo[0].round,
        category: newAvailableTo[0].currency_type,
      };

    setFrom(option);
    setTo(newTo);
    clearError("from");
  };

  const handleToChange = (option: CurrencyOption) => {
    setTo(option);
    clearError("from");
  };

  const handleCityChange = (option: _Select) => setCity(option);

  const swap = () => {
    if (!to || !from) {
      return;
    }
    const tmp = from;
    setFrom(to);
    setTo(tmp);
  };

  const validate = (): boolean => {
    if (!course) {
      return true;
    }

    let from = "";
    let telegramError = "";

    if (!amount.from?.length || Number(amount.from) === 0) {
      from = "Это обязательное поле";
    }

    if (telegram.length < 6) {
      telegramError = "Минимальная длина - 5 символов";
    }

    if (!TELEGRAM_REGEX.test(telegram)) {
      telegramError = "Некорректный формат";
    }

    setError({
      from,
      telegram: telegramError,
    });

    return !!from?.length || !!telegramError?.length;
  };

  const submit = async () => {
    if (validate()) {
      return;
    }
    const search_utm_sourse = searchParams?.get("utm_source");
    const search_utm_medium = searchParams?.get("utm_medium");
    let utm_source = sessionStorage.getItem("utmid") || "";
    let utm_medium = sessionStorage.getItem("utm_medium") || "";
    const roistat_visit = Cookies.get("roistat_visit");
    if (!!search_utm_sourse) {
      utm_source = search_utm_sourse;
    }
    if (!!search_utm_medium) {
      utm_medium = search_utm_medium;
    }
    const ref_id = sessionStorage.getItem(rid_attr);
    const currency_from = directions?.find((el) => el.give.currency_code === from?.value);
    const currency_to = currency_from?.get.find((el) => el.currency_code === to?.value);

    metrika("order");
    const payload: OrderPayload = {
      telegram: telegram || "",
      utm: utm_source,
      utm_medium,
      ref_id: !!ref_id ? ref_id : null,
      roistat_visit: roistat_visit || null,
      trade: {
        give: from?.value || "",
        get: to?.value || "",
        type_give: currency_from?.give.currency_type || null,
        type_get: currency_to?.currency_type || null,
        amount_give: amount.from,
        amount_get: amount.to,
        rate_give: course?.rate_give || 0,
        rate_get: course?.rate_get || 0,
        city_code: city?.value || "",
      },
    };

    const data = await createBid(payload);
    if (!!data && !error) {
      setOrderId(data?.trade_id);
    }
  };

  useEffect(() => {
    if (!!order && !orderError) {
      const tg_payload: TG_OrderPayload = {
        chat_id: CHAT_ID,
        text: `
ID Заявки: ${order.id}
Заявка создана: ${order.created}
Город: ${order.trade.cash_point?.ru}
Сумма заявки: ${order.trade.amount_give}${order.trade.give} - ${order.trade.amount_get}${order.trade.get}
Курс: ${order.trade.rate_give}${order.trade.give} - ${order.trade.rate_get}${order.trade.get}
${order.info.terms || ""}
        `,
        parse_mode: "HTML",
        message_thread_id: v4(),
        disable_web_page_preview: true,
      }
      createBidTg(tg_payload);
    }
  }, [order])

  return {
    handleAmountChange,
    handleTelegramChange,
    handleFromChange,
    handleToChange,
    handleCityChange,
    swap,
    submit,
    isPending: isPending || isPendingTg || isOrderPending,
    isError: !!error || !!errorTg,
  };
};
