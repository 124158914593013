import { roistatChat, roistatOrder, roistatTelegramGoal } from "./roistat";
import { yCreateOrder, yOpenTg, yTalkMe } from "./ya";

export const metrika = (type: "tg" | "order" | "chat") => {
  switch (type) {
    case "tg":
      yOpenTg();
      roistatTelegramGoal();
      break;
    case "order":
      yCreateOrder();
      roistatOrder();
      break;
    case "chat":
      yTalkMe();
      roistatChat();
      break;
  }
};
