// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck - Yandex Metrika

export const YANDEX_METRIKA_ID = import.meta.env.VITE_YANDEX_METRIKA_ID;

export const yCreateOrder = () => {
  if (window?.ym) {
    window.ym(YANDEX_METRIKA_ID, "reachGoal", "order");
  }
};

export const yTalkMe = () => {
  if (window?.ym) {
    window.ym(YANDEX_METRIKA_ID, "reachGoal", "chat");
  }
};

export const yOpenTg = () => {
  if (window?.ym) {
    window.ym(YANDEX_METRIKA_ID, "reachGoal", "tg");
  }
};
